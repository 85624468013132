<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<template>
  <section class="faq-section">
    <div class="container">
      <!-- <div class="faq-section__title">
        <section-title> FAQ </section-title>
        <a href="https://docs.jpool.one/" target="blank" title="jpool docs">More FAQ in the JPool documentation</a>
      </div> -->
      <div class="page-container">
        <!-- <div class="faq-section__cede-block">
          <cede-link class="q-mt-md q-mb-xl q-mx-auto"/>
        </div> -->
        <how-to-stake-btn />
        <div class="faq-list">
          <faq-item :title="$t('faq.staking.title')">
            <p>
              {{ $t('faq.staking.descr') }}
            </p>
            <p v-html="$t('faq.staking.readMore')" />
          </faq-item>
          <faq-item :title="$t('faq.stake.title')">
            <p>
              {{ $t('faq.stake.descr') }}
            </p>
          </faq-item>
          <faq-item :title="$t('faq.rewards.title')">
            <p>
              {{ $t('faq.rewards.descr') }}
            </p>
            <p v-html="$t('faq.rewards.readMore')" />
          </faq-item>
          <faq-item :title="$t('faq.delegator.title')">
            <p v-html="$t('faq.delegator.descr')" />
          </faq-item>
        </div>
      </div>
    </div>
  </section>
</template>
