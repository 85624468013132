<template>
  <div class="container redelegate-skeleton-container">
    <div class="row justify-center">
      <div class="relative-position col-12 col-md-6">
        <div class="column justify-center items-center q-pb-sm">
          <div class="q-mb-xs">
            <q-skeleton height="22px" width="143px" />
          </div>
          <div>
            <q-skeleton height="22px" width="318px" />
          </div>
        </div>

        <q-skeleton height="22px" width="270px" class="q-mx-auto" />
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.redelegate-skeleton-container {
  .q-skeleton {
    border-radius: $border-radius;
  }
}
</style>
