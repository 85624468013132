<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import { useWallet } from 'solana-wallets-vue'
import { JSTAKING_VOTE } from '~/config'
import type { StakeAccount } from '~/store'
import { useStakeAccountStore } from '~/store'

const wallet = useWallet()
const { connected } = wallet

const stakeAccountStore = useStakeAccountStore()
const loadingPubkeys = computed(() => stakeAccountStore.loadingPubkeys)

const validatorsStore = useValidatorsStore()
const getValidator = (voteId: string) => validatorsStore.allValidators.find(v => v.voteId === voteId)

const accountsFull = computed(() => {
  return stakeAccountStore.accountsFull
    .map((acc) => {
      return {
        ...acc,
        validator: getValidator(acc.stakeAccount.account.data?.parsed?.info?.stake?.delegation?.voter),
      }
    })
    .filter(acc => acc.state === 'active' && acc.validator && acc.validator.voteId !== JSTAKING_VOTE && acc.validator.inJpool)
})

const loading = computed(() => stakeAccountStore.loading)
async function depositJpool(stakeAccount: StakeAccount) {
  stakeAccountStore.depositJpool([stakeAccount])
}
</script>

<template>
  <section>
    <div class="container">
      <redelegate-skeleton v-if="loading" />
      <div v-else class="row justify-center">
        <div class="relative-position col-12 col-md-6">
          <div class="column justify-center items-center q-pb-sm">
            <div class="redelegation__title">
              {{ $t('smartStaking.redelegation.title') }}
            </div>
            <div class="redelegation__subtitle">
              {{ $t('smartStaking.redelegation.subtitle') }}
            </div>
          </div>

          <div v-if="!connected" class="redelegation__subtitle text-center text-weight-medium text-body1">
            {{ $t('smartStaking.redelegation.notConnected') }}
          </div>

          <div v-else class="full-width">
            <q-list v-if="accountsFull.length > 0" separator class="q-mt-sm">
              <stake-item-redelegate
                v-for="(acc) in accountsFull" :key="acc.stakeAccount.pubkey.toBase58()"
                class="q-mb-md"
                :stake-account="acc.stakeAccount"
                :validator="acc.validator"
                :loading="loadingPubkeys.has(acc.stakeAccount.pubkey.toBase58())"
                @deposit-jpool="depositJpool"
              />
            </q-list>

            <div v-else class="redelegation__subtitle text-center text-weight-medium text-body1">
              {{ $t('smartStaking.redelegation.noAccounts') }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
