<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts">
import { Dark, Notify } from 'quasar'
import { useWallet } from 'solana-wallets-vue'
import jsolIcon from '~/assets/img/icon/jsol.svg?raw'
import jpoolLogo from '~/assets/img/logo/jpool-logo.svg'

import { JSOL_LOGO, MIN_REMAINING_BALANCE } from '~/config'
import { useWithdraw } from '~/hooks'
import {
  useApyStore,
  useBalanceStore,
  useStakePoolStore,
} from '~/store'
import { formatAmount, formatMoney, formatPct, onlyNumber } from '~/utils'

enum Tab {
  stake = 'stake',
  unstake = 'unstake',
}

export default defineComponent({
  setup() {
    const stakePoolStore = useStakePoolStore()
    const balanceStore = useBalanceStore()
    const apyStore = useApyStore()
    const { connected, publicKey } = useWallet()
    const depositStore = useDepositStore()
    const { withdrawing, setAmount, withdraw, useWithdrawSol }
      = useWithdraw()
    const apy = computed(() => apyStore.apy.staking + apyStore.apy.jito)
    const solBalance = computed(() => balanceStore.solBalance)
    const tokenBalance = computed(() => balanceStore.tokenBalance)
    const fees = computed(() => stakePoolStore.fees)
    const exchangeRate = computed(() => stakePoolStore.exchangeRate)
    const connectionLost = computed(() => stakePoolStore.connectionLost)
    const stake = reactive<{ from: any, to: any }>({
      from: null,
      to: null,
    })
    const unstake = reactive<{ from: any, to: any }>({
      from: null,
      to: null,
    })
    const tab = ref(Tab.stake)
    const stakeFromInput = ref<any>(null)
    const unstakeFromInput = ref<any>(null)

    const { isClient } = useApp()
    const { t } = useTranslation()

    // Calculate amount to deposit
    const depositAmount = computed(() => {
      const sol = Number(stake.from)
      if (sol <= 0) {
        return 0
      }
      let value = sol * exchangeRate.value
      value -= value * fees.value.solDepositFee
      return value > 0 ? value : 0
    })
    // Calculate amount to withdraw
    const withdrawAmount = computed(() => {
      const sol = Number(unstake.from)
      if (sol <= 0) {
        return 0
      }
      // let value = (sol - lamportsToSol(withdrawFee.value)) * (1 / exchangeRate.value)
      let value = sol * (1 / exchangeRate.value)
      value -= value * fees.value.withdrawalFee
      return value > 0 ? value : 0
    })
    const withdrawInstantAmount = computed(() => {
      const sol = Number(unstake.from)
      if (sol <= 0) {
        return 0
      }
      // let value = (sol - lamportsToSol(withdrawSolFee.value)) * (1 / exchangeRate.value)
      let value = sol * (1 / exchangeRate.value)
      value -= value * fees.value.solWithdrawalFee
      return value > 0 ? value : 0
    })
    watch(depositAmount, (amount) => {
      if (!amount) {
        stake.to = null
        return
      }
      stake.to = amount > 0 ? amount?.toFixed(5) : 0
    })
    watch(withdrawAmount, (amount) => {
      if (!amount) {
        unstake.to = null
        return
      }
      setAmount(unstake.from)
      unstake.to = amount > 0 ? amount?.toFixed(5) : 0
    })

    const highlightFix = ref(true)
    const unstakeType = ref('')
    const solToJsolRate = computed(() =>
      exchangeRate.value === 1 ? 1 : formatAmount(exchangeRate.value),
    )
    const stakeSources = computed(() => {
      return [
        {
          name: t('stakeBox.yourWallet'),
          address: publicKey.value,
          balance: solBalance.value || '0',
        },
        {
          name: 'Stake Ggte..JNrg',
          address: 'GgtefatXbBHJFFy3VXkj5w7imcEemEi3TYmvMVWeJNrg',
          balance: '0.5',
        },
      ]
    })
    const stakeSource = ref(stakeSources.value[0])
    watch(solBalance, () => {
      if (stakeSource.value?.name === t('stakeBox.yourWallet')) {
        stakeSource.value.balance = solBalance.value
      }
    })

    const coinRateStore = useCoinRateStore()
    const solPrice = computed(() => coinRateStore.solana?.price)
    const jsolPrice = computed(() => coinRateStore.jpool?.price)

    function onFocus() {
      highlightFix.value = true
    }
    function onBlur() {
      highlightFix.value = false
    }

    onMounted(() => {
      nextTick(() => {
        if (stakeFromInput.value) {
          stakeFromInput.value?.focus()
          if (isClient.value) {
            const inputElement = stakeFromInput.value.$el
            onClickOutside(inputElement, () => {
              onBlur()
            })
          }
        }
      })
    })

    return {
      tab,
      Dark,
      stake,
      unstake,
      publicKey,
      jsolIcon,
      JSOL_LOGO,
      connected,
      jpoolLogo,
      depositStore,
      withdrawing,
      stakeFromInput,
      unstakeFromInput,
      connectionLost,
      useWithdrawSol,
      unstakeType,
      fees,
      apy: computed(() => formatPct.format(apy.value)),

      stakeInfoData: computed(() => {
        const from = stake.from
        // const depositFeeVal = lamportsToSol(depositFee.value)
        // const value = from ? (from - depositFeeVal) * exchangeRate.value : 0
        return [
          {
            name: t('stakeBox.detailInfo.solToStake'),
            value: `${from || '0'} SOL`,
            value2: `$${formatMoney(from * solPrice.value)}`,
          },
          {
            name: t('stakeBox.detailInfo.youGet'),
            value: `${stake.to ? stake.to : '0'} JSOL`,
            value2: `$${formatMoney(stake.to * jsolPrice.value)}`,
            isBold: true,
          },
        ]
      }),
      unstakeInfoData: computed(() => {
        const from = unstake.from

        return [
          {
            name: t('stakeBox.detailInfo.jsolToUnstake'),
            value: `${from || '0'} JSOL`,
          },

          {
            name: t('stakeBox.detailInfo.youGet'),
            value: `${unstake.to ? unstake.to : '0'} SOL`,
            isBold: true,
          },
        ]
      }),
      unstakeInstantInfoData: computed(() => {
        const from = unstake.from

        return [
          {
            name: t('stakeBox.detailInfo.jsolToUnstake'),
            value: `${from || '0'} JSOL`,
          },
          {
            name: t('stakeBox.detailInfo.youGet'),
            value: `${withdrawInstantAmount.value > 0 ? withdrawInstantAmount.value?.toFixed(5) : 0
            } SOL`,
            isBold: true,
          },
        ]
      }),

      availableSol: computed(() => (solBalance.value ? solBalance.value : 0)),
      availableJsol: computed(() => (tokenBalance.value ? tokenBalance.value : 0)),

      solDepositFee: computed(() => fees?.value.solDepositFee),
      withdrawalFee: computed(() => fees?.value.withdrawalFee),

      solPrice,
      jsolPrice,
      solToJsolRate,
      jsolToSolRate: computed(() =>
        exchangeRate.value === 1 ? 1 : formatAmount(1 / exchangeRate.value),
      ),

      stakeSource,
      stakeSources,
      stakeMax() {
        if (!connected.value) {
          Notify.create({
            message: t('notify.walletNotConnected.message'),
            caption: t('notify.walletNotConnected.caption'),
          })
          return
        }
        stake.from = Math.max(Number((solBalance.value - MIN_REMAINING_BALANCE).toFixed(9)), 0)
      },
      unstakeMax() {
        if (!connected.value) {
          Notify.create({
            message: t('notify.walletNotConnected.message'),
            caption: t('notify.walletNotConnected.caption'),
          })
          return
        }
        unstake.from = tokenBalance.value
      },
      stakeHandler: async () => {
        if (depositAmount.value <= 0) {
          stakeFromInput.value?.focus()
          return
        }
        // await depositSol(stake.from - lamportsToSol(depositFee.value))
        await depositStore.depositSol(stake.from)
        stake.from = 0
        stake.to = 0
        Analytics.track({ event: 'Smart stake', action: 'depositSol', label: 'Smart staking' })
      },
      unstakeHandler: async (forceDelayed = false) => {
        if (withdrawAmount.value <= 0) {
          unstakeFromInput.value?.focus()
          return
        }
        unstakeType.value = forceDelayed ? 'delayed' : 'instant'
        await withdraw(forceDelayed)

        const eventLabel = unstakeType.value === 'instant' ? 'Instant unstaking' : 'Delayed unstaking'
        Analytics.track({ event: eventLabel, action: 'withdraw', label: eventLabel })

        unstakeType.value = ''

        unstake.from = 0
        unstake.to = 0
      },
      formatMoney,
      formatPct(v: number) {
        return formatPct.format(v)
      },
      onlyNumber,
      highlightFix,
      onBlur,
      onFocus,
    }
  },
})
</script>

<template>
  <q-card v-bind="$attrs" class="stake-box" :dark="Dark.isActive">
    <q-tabs
      v-model="tab" align="justify" indicator-color="transparent" active-bg-color="secondary"
      active-color="black"
    >
      <q-tab :ripple="false" :label="$t('stakeBox.tabs.stake')" name="stake" :disable="connectionLost" />
      <q-tab :ripple="false" :label="$t('stakeBox.tabs.unstake')" name="unstake" :disable="connectionLost" />
    </q-tabs>

    <q-tab-panels v-model="tab" animated class="bg-transparent">
      <q-tab-panel name="stake">
        <!-- <q-card-section class="stake-box__section" :style="{ minHeight: '12px' }">
          <div class="stake-box__source row items-end">
            <div class="stake-box__source__label col-6 q-pr-md">
              <div v-html="$t('stakeBox.title')" />
            </div>
            <div class="col-6 row justify-end">
              <q-btn-dropdown
                class="stake-box__source__dropdown"
                :color="isDark ? 'primary' : 'white'"
                :text-color="isDark ? 'white' : 'primary'"
                unelevated
                disable
              >
                <template #label>
                  <div v-if="stakeSource">
                    <div class="stake-box__source__name q-mb-xs">
                      {{ stakeSource.name }}
                    </div>
                    <div class="row items-center">
                      <i-app-sol class="stake-box__source__icon gt-xs" />
                      <div class="stake-box__source__balance">
                        {{ stakeSource.balance }} SOL
                      </div>
                    </div>
                  </div>
                </template>

                <q-list>
                  <q-item
                    v-for="source in stakeSources" :key="source.name" v-close-popup clickable
                    @click="stakeSource = { ...source }"
                  >
                    <q-item-section>
                      <q-item-label class="text-body2">
                        {{ source.name }}
                      </q-item-label>
                      <q-item-label caption>
                        {{ source.balance }} SOL
                      </q-item-label>
                    </q-item-section>
                  </q-item>
                </q-list>
              </q-btn-dropdown>
            </div>
          </div>
        </q-card-section> -->

        <q-card-section class="stake-box__section">
          <q-input
            ref="stakeFromInput" v-model="stake.from" :maxlength="14" class="stake-box__input"
            :class="{ 'stake-box__input--highlight-fix': highlightFix }" outlined placeholder="0.0"
            :readonly="connectionLost" :dark="Dark.isActive" @focus="onFocus"
            @keypress="onlyNumber"
          >
            <template #prepend>
              <i-app-sol class="stake-field__icon" />
            </template>

            <template #append>
              <div class="stake-box__input__append">
                <q-btn
                  dense :color="Dark.isActive ? 'primary' : 'white'"
                  :text-color="Dark.isActive ? 'white' : 'primary'" unelevated size="13px" class="q-ml-auto"
                  padding="2px 16px 2px" style="font-weight: 400" :ripple="false" @click="stakeMax"
                >
                  {{ $t('common.max') }}
                </q-btn>
                <div class="stake-box__input__balance">
                  {{ $t('common.balance') }}: {{ availableSol }}
                </div>
              </div>
            </template>

            <template #default>
              <div v-if="connected && Number(stake.from) > Number(availableSol)" class="stake-box__warning">
                {{ $t('stakeBox.warning') }}
              </div>
              <div v-if="solPrice" class="stake-box__input__inner">
                ${{ formatMoney(stake.from * solPrice) }}
              </div>
              <div v-else class="stake-box__input__inner">
                $0
              </div>
            </template>
          </q-input>
        </q-card-section>

        <stake-box-holders-club />

        <q-card-section class="stake-box__section">
          <div class="row items-center q-pt-sm q-pb-lg">
            <div class="stake-box__stake-calc">
              <div class="stake-box__stake-calc__label">
                {{ $t('stakeBox.willReceive') }}:
              </div>
              <div class="stake-box__stake-calc__value">
                <div>
                  <i v-html="jsolIcon" />
                  {{ stakeInfoData[stakeInfoData.length - 1]?.value }}
                </div>

                <div>
                  {{ apy }} APY
                </div>
              </div>
            </div>

            <q-btn
              v-if="connected" :loading="depositStore.depositing" class="stake-box__btn" color="secondary" text-color="black"
              unelevated :disable="connectionLost || Number(stake.from) > Number(availableSol)" @click="stakeHandler"
            >
              {{ $t('stakeBox.action.stakeNow') }}
            </q-btn>
            <div v-else class="text-right stake-box__connect">
              <connect-wallet class="stake-box__wallet-btn" />
            </div>
          </div>
        </q-card-section>

        <!-- <q-card-section class="stake-box__section q-pt-none q-mt-xs">
          <div class="row justify-center full-width">
            <stake-info is-expanded :data="stakeInfoData" :is-stake="true" class="q-mt-sm" />
          </div>
        </q-card-section> -->
      </q-tab-panel>

      <q-tab-panel name="unstake">
        <q-card-section class="stake-box__section">
          <div class="stake-box__rate full-width text-center">
            1 JSOL = {{ jsolToSolRate }} SOL
          </div>
        </q-card-section>

        <q-card-section class="stake-box__section with-arrow">
          <q-input
            ref="unstakeFromInput" v-model="unstake.from" :maxlength="14" class="stake-box__input" outlined
            placeholder="0.0" :readonly="connectionLost" @keypress="onlyNumber"
          >
            <template #prepend>
              <img :src="JSOL_LOGO" alt="solana" class="stake-field__icon">
            </template>

            <template #append>
              <div class="stake-box__input__append">
                <q-btn
                  dense :color="Dark.isActive ? 'primary' : 'white'"
                  :text-color="Dark.isActive ? 'white' : 'primary'" unelevated size="13px" class="q-ml-auto"
                  padding="2px 16px 2px" style="font-weight: 400" :ripple="false" @click="unstakeMax"
                >
                  {{ $t('common.max') }}
                </q-btn>
                <div class="stake-box__input__balance">
                  {{ $t('common.balance') }}: {{ availableJsol }}
                </div>
              </div>
            </template>

            <template #default>
              <div v-if="connected && Number(unstake.from) > Number(availableJsol)" class="stake-box__warning">
                {{ $t('stakeBox.warningUnstake') }}
              </div>
              <div v-if="solPrice" class="stake-box__input__inner">
                ${{ formatMoney(unstake.from * jsolPrice) }}
              </div>
              <div v-else class="stake-box__input__inner">
                $0
              </div>
            </template>
          </q-input>
        </q-card-section>

        <q-card-section class="stake-box__section row items-center q-pb-none">
          <div class="stake-box__unstake-calc col-sm-6 col-xs-12">
            <div class="stake-box__unstake-calc__label">
              {{ $t('stakeBox.amountToUnstake') }}
            </div>
            <div class="row items-center">
              <i-app-sol class="stake-box__unstake-calc__logo" />
              <div class="row items-end">
                <div class="stake-box__unstake-calc__value">
                  {{ unstake.to ?? 0 }}
                </div>
                <div class="stake-box__unstake-calc__symbol">
                  SOL
                </div>
              </div>
            </div>
          </div>
          <div class="stake-box__unstake-calc row col-sm-6 col-xs-12">
            <div class="row justify-center stake-box__note q-mt-sm">
              {{ $t('stakeBox.networkFee') }}
            </div>
          </div>
        </q-card-section>

        <q-card-section class="stake-box__section q-mb-xs">
          <div class="row items-between">
            <div class="column col-sm-6 col-xs-12 justify-between stake-box__unstake-action">
              <stake-info
                v-if="useWithdrawSol && connected" :data="unstakeInstantInfoData" :is-stake="false"
                class="stake-info--sm"
              />

              <div v-else-if="connected" class="row justify-center">
                <div v-html="$t('stakeBox.reserveNote')" />
                <q-btn
                  type="a"
                  href="https://jup.ag/swap/JSOL-SOL"
                  target="_blank" label="SWAP JSOL/SOL" class="stake-box__btn full-width q-mt-md"
                  :text-color="Dark.isActive ? 'black' : 'white'" :color="Dark.isActive ? 'white' : 'info'"
                  unelevated :ripple="false"
                />
              </div>

              <q-btn
                v-if="connected" :loading="withdrawing && unstakeType === 'instant'"
                class="stake-box__btn-unstake q-mt-sm" color="secondary" unelevated size="lg" text-color="black"
                :ripple="false" :disable="connectionLost
                  || !useWithdrawSol
                  || unstakeType === 'delayed'
                  || Number(unstake.from) > Number(availableJsol)
                " @click="unstakeHandler(false)"
              >
                <div class="stake-box__btn-unstake__main-text">
                  {{ $t('stakeBox.action.unstakeNow') }}
                </div>
              </q-btn>
            </div>

            <div class="column col-sm-6 col-xs-12 stake-box__unstake-action">
              <stake-info v-if="connected" :data="unstakeInfoData" :is-stake="false" class="stake-info--sm q-mb-auto" />
              <q-btn
                v-if="connected" :loading="withdrawing && unstakeType === 'delayed'"
                class="stake-box__btn-unstake q-mt-sm" :color="Dark.isActive ? 'info' : 'primary'" unelevated
                size="lg" text-color="white" :ripple="false" :disable="connectionLost
                  || unstakeType === 'instant'
                  || Number(unstake.from) > Number(availableJsol)
                " @click="unstakeHandler(true)"
              >
                <div class="stake-box__btn-unstake__main-text">
                  {{ $t('stakeBox.action.unstakeDelayed') }}
                </div>
              </q-btn>
              <div v-else class="text-right q-mt-auto" :class="{ 'full-width': $q.screen.lt.sm }">
                <connect-wallet class="stake-box__wallet-btn" :class="{ 'full-width': $q.screen.lt.sm }" />
              </div>
            </div>

            <!-- <instant-unstake v-if="connected" :amount="unstake.from" /> -->
          </div>
        </q-card-section>
      </q-tab-panel>
    </q-tab-panels>
  </q-card>
  <stake-success-dialog v-model="depositStore.stakeSuccessDialog" :is-membership="depositStore.toBeMembership" />
</template>
