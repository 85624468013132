<!--
  - This file is part of Solana Reference Stake Pool code.
  -
  - Copyright © 2023, mFactory GmbH
  -
  - Solana Reference Stake Pool is free software: you can redistribute it
  - and/or modify it under the terms of the GNU Affero General Public License
  - as published by the Free Software Foundation, either version 3
  - of the License, or (at your option) any later version.
  -
  - Solana Reference Stake Pool is distributed in the hope that it
  - will be useful, but WITHOUT ANY WARRANTY; without even the implied
  - warranty of MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.
  - See the GNU Affero General Public License for more details.
  -
  - You should have received a copy of the GNU Affero General Public License
  - along with this program.
  - If not, see <https://www.gnu.org/licenses/agpl-3.0.html>.
  -
  - You can be released from the requirements of the Affero GNU General Public License
  - by purchasing a commercial license. The purchase of such a license is
  - mandatory as soon as you develop commercial activities using the
  - Solana Reference Stake Pool code without disclosing the source code of
  - your own applications.
  -
  - The developer of this program can be contacted at <info@mfactory.ch>.
  -->

<script lang="ts" setup>
import dolphinIcon from '~/assets/img/icon/dolphin.svg'
import moneyIcon from '~/assets/img/icon/money-grouwth.svg'
import solIcon from '~/assets/img/icon/sol-bordered.svg'

type FaqSpec = {
  title: string
  texts: { text: string, isBold?: boolean }[]
  icon: string
  link: string
}

const { t } = useTranslation()

const data: FaqSpec[] = [
  {
    title: t('faq.special.sol.title'),
    texts: [
      { text: t('faq.special.sol.text.1') },
      { text: 'SOL', isBold: true },
      { text: t('faq.special.sol.text.2') },
    ],
    icon: dolphinIcon,
    link: 'https://www.orca.so',
  },
  {
    title: t('faq.special.cryptocurrency.title'),
    texts: [
      {
        text: t('faq.special.cryptocurrency.text.1'),
      },
    ],
    icon: moneyIcon,
    link: 'https://www.binance.com',
  },
  {
    title: t('faq.special.wallet.title'),
    texts: [
      {
        text: t('faq.special.wallet.text.1'),
      },
      { text: t('faq.special.wallet.text.2'), isBold: true },
    ],
    icon: solIcon,
    link: 'https://phantom.app/',
  },
]
</script>

<template>
  <section class="faq-special">
    <div class="container">
      <div class="page-container">
        <div class="faq-special__items">
          <faq-special-item
            v-for="item in data"
            :key="item.title"
            :title="item.title"
            :texts="item.texts"
            :link="item.link"
          >
            <template #icon>
              <img :src="item.icon" alt="">
            </template>
          </faq-special-item>
        </div>
      </div>
    </div>
  </section>
</template>
